
 @page {
  size : A4;
//  margin : 10mm;
 

 .refDiv {
  break-after : page; 

  > div > div:not(.dividerDiv){
    page-break-inside: avoid;
    page-break-after:  auto;
     } 
  }  
} 

.refDiv > div > div + .refDiv > div > div {
  margin-top: 40px;
}

@media print{
  .refDiv > div > div +  .refDiv > div > div{
    margin-top: 0;
    } 
}