DayText.dateMenu {
  position: relative;
  display: block;
  width: 100%;
  min-width: 126px;
}
.dateButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  color: #999;
  cursor: pointer;
  border: 1px solid #999;
  min-width: 126px;
}
.smallDateButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 8px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  color: #999;
  cursor: pointer;
  border: 1px solid #999;
}
.open {
  border: 1px solid #6c62d1;
}

.menuItems {
  position: absolute;
  // min-width: 324px;
  // min-height: 488px;
  min-width: 280px;
  min-height: 280px;
  // margin-top: 24px;
  margin-top: 8px;
  z-index: 10;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.body {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 4px;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.day {
  opacity: 0.2;
  margin: 4px;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.holiday {
    color: #ff6969;
    cursor: default;
  }
  &:nth-child(1) {
    color: #ff6969;
  }
  &:nth-child(7) {
    color: #0085ff;
  }
}

.isCurrentMonth {
  opacity: 1;
  cursor: pointer;
}
.disabledDate {
  opacity: 0.2 !important;
}

.isSelected {
  background: #6c62d1;
  border-radius: 16px;
  color: white !important;
}

.isCurrentDate {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    background: #ff6969;
    border-radius: 100%;
  }
}
